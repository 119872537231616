* {float: left;width: 100%;box-sizing: border-box;}
body {float: left;width: 100%;font-family:"Avenir-LT-W01_85-Heavy1475544";box-sizing: border-box;position: relative;letter-spacing: 0.3px;}
.wrapper {float: left;width: 100%;overflow: hidden;padding-top: 129px;}
.container {float: none;}
.container-fluid.custom-container-fluid {padding: 0 20px;}

/* header css start here */
.header_section {float: left;width: 100%;position: fixed;top: 0;z-index: 999;background-color: #fff;}
.header_section .logowraphead {float: left;width: 158px;}
.header_section .logowraphead img {width: 100%;}
.headerinfolinks {float: right;max-width: 600px;text-align: right;display: flex;flex-direction: column;align-items: flex-end;}
.headeraccounts {float: right;width: auto;padding: 18px 0 29px;display: flex;align-items: flex-end;position: relative;}
.headeraccounts h2 {float: left;width: auto;color: #000000;font-size: 19px;margin: 0px 35px 0px 0px;font-family:"Avenir-LT-W01_85-Heavy1475544";}
.loginwrapsbtn {float: left;width: auto;cursor: pointer;display: flex;align-items: center;justify-content: center;vertical-align: middle;line-height: normal;}
.loginwrapsbtn h5 {float: left;width: auto;color: #000000;font-size: 18px;line-height: normal;margin: 0px 12px 0px 0px;font-family:"Avenir-LT-W01_85-Heavy1475544";line-height: normal;}
.loginwrapsbtn svg {float: left;width: 25px;height: 25px;}
.headerLinks {float: left;width: 100%;}
.headerLinks ul {float: left;width: 100%;display: flex;margin: 0px;padding: 0px;justify-content: right;vertical-align: middle;align-items: flex-end;}
.headerLinks ul li {float: right;width: auto;list-style: none;}
.headerLinks ul li.active a {color: #757575;}
.headerLinks ul li a {color: #000000;text-decoration: none;padding: 5px 18px;font-size: 17px;font-family:"Avenir-LT-W01_85-Heavy1475544";position: relative;line-height: normal;}
.headerLinks ul li:last-child a::after {display: none;}
.headerLinks ul li a::after {content: "";float: left;width: 1px;height: 100%;background-color: #000000;position: absolute;top: 0;right: 0;}
.headerLinks ul li a:hover {color: rgb(117, 117, 117);}
.headerLinks ul li:last-child a{padding-right: 0px;}
/* header css end here */

/* about us css start here */
.aboutuspage {float: left;width: 100%;}
.digitalposters {float: left;width: 100%;position: relative;background-image: url("../img/background.gif");background-repeat: no-repeat;background-size: cover;background-position: center;}
.videoOvarlay {float: left;width: 100%;height: 100%;position: absolute;left: 0;top: 0;background: rgba(255, 255,255, 0.8);}
.digitalposterssliders {float: left;width: 100%;z-index: 99;position: relative;padding: 10px 0px 80px;}
.commonheader {float: left;width: 100%;text-align: center;margin-bottom: 26px;}
.commonheader h1 {line-height: normal;margin: 0 0 16px;}
.commonheader h4 {font-size: 22px;margin: 0 0 30px;}
.posterwrapps {float: left;width: 100%;position: relative;overflow: hidden;}
.slick-slide div {outline: none;}
.digitalposterssliders .slick-list .slick-slide{padding: 8px;}
.posterlistovarlay {float: left;width: 100%;height: 100%;position: absolute;left: 0;top: 0;background: rgba(255, 255,255, 0.5);display: none;}
.slick-slide div:hover .posterlistovarlay {display: block;}
.slick-slide div .posterlistovarlay .customCheckBoxNew {width: auto;position: absolute;bottom: 35px;left: 19px;}
/* about us css end here */

/* purchess css start here */
.PurchesPostersction {float: left;width: 100%;position: relative;padding: 35px 0;}
.purchesswraps {float: none;width: 75%;margin: 0 auto;display: table;}
.purchespassage {float: left;width: 100%;font-size: 16px;padding-right: 20px;}
.purchessingletypes {float: none;width: 310px;position: relative;margin: 0 auto 35px;display: table;}
.purchessingletypes img {width: 225px;margin: 0 auto 8px;display: table;float: none;}
.Purchasebtn, .Purchasebtn:focus {background-color: #121212;border-radius: 30px;box-shadow: none !important;padding: 1px 10px;color: #fff !important;font-size: 16px;border-color: #121212;max-width: 265px;margin: 0 auto;float: none;display: table;}
.Purchasebtn:hover {background-color: #FF4040;border-color: #FF4040;}
.purchesBundletypes {float: none;width: 310px;position: relative;margin: 0 auto 35px;display: table;}
.purchesBundleGroups img {width: 225px;}
.purchesBundleGroups {float: left;width: 100%;margin-bottom: 10px;position: relative;height: 262px;}
.purchesBundleGroups img:first-child {position: absolute;right: 0;top: 30px;}
.purchesBundleGroups img:nth-child(2) {position: absolute;left: 0;top: 0;}
.purchesBundleGroups img:last-child {float: none;margin: 0 auto;display: table;position: absolute;left: 0;right: 0;bottom: 0;}
.Purchasebtnbig, .Purchasebtnbig:focus{background-color: #121212;box-shadow: none !important;border-radius: 30px;color: #fff !important;padding: 9px 10px;font-size: 17px;border-color: #121212;margin: 0px auto 0;float: none;display: table;}
.Purchasebtnbig:hover {background-color: #FF4040;border-color: #FF4040;}
.PosterBundleswraper .purchesBundleGroups {margin-bottom: 20px;}
/* purchess css end here */

/* footer section css start here */
.footer_section {float: left;width: 100%;background-color: #121212;position: relative;padding: 50px 0px 100px;}
.container.custom_container {max-width: 800px;display: table;}
.collinksfoot {float: left;width: 100%;}
.collinksfoot ul {margin: 0px;padding: 0px;}
.collinksfoot ul li {float: left;width: 100%;list-style: none;padding-bottom: 7px;}
.collinksfoot ul li a {color: #fff;text-decoration: none;font-size: 17px;}
.footermailjoin {float: left;width: 100%;margin: 50px 0 0;color: #fff;}
.footermailjoin p{font-size: 17px;font-family: "Avenir-LT-W05_35-Light";}
.footermailjoin .footemialform {float: left;width: 100%;}
.footermailjoin .footemialform label {float: left;width: 100%;color: #696969;margin: 0 0 5px;}
.footermailjoin .footemialform .form-group {float: left;width: 100%;display: flex;}
.footermailjoin .footemialform .form-group input {background: transparent;box-shadow: none;border: 0px;border-bottom: 2px solid #fff;margin-right: 18px;color: #fff;padding: 10px 22px;font-size: 15px;outline: none;border-radius: 0px;}
.footermailjoin .footemialform .form-group input::placeholder {color: #fff;}
.footermailjoin .footemialform .form-group .btn, .footermailjoin .footemialform .form-group .btn:focus {width: 210px;box-shadow: none !important;padding: 9px 20px;border-radius: 0px;background-color: #fff;border-color: #fff;color: #121212;}
.footermailjoin .footemialform .form-group .btn:hover {background-color: #0E3E9B;border-color: #0E3E9B;color: #fff;}
.footercopyright {float: left;width: 100%;text-align: center;background-color: rgba(117,117,117);padding: 11px 0;font-size: 17px;color: #121212;letter-spacing: 0.6px;}
/* footer section css end here */

/* single poster css start here */
.singlepostersection {float: left;width: 100%;padding: 20px 0;}
.sectionheader {text-align: center;margin: 0 0 20px;}
.posterblogswrp {float: left;width: 100%;border: 1px solid #ddd;margin-bottom: 20px;position: relative;}
.posterblogswrpdetails {float: left;width: 100%;padding: 14px 15px 42px 15px;text-align: center;position: relative;}
.posterblogswrpdetails h5{font-size: 14px;margin: 8px 0 14px;}
.postaddcart {float: left;width: 100%;display: flex;justify-content: center;align-items: center;}
.postaddcart h4 {width: auto;font-size: 20px;text-align: center;margin: 0px;}
.addtocart, .addtocart:focus {background-color: #121212;box-shadow: none !important;border-color: #121212;font-size: 16px;padding: 0px 10px;border-radius: 30px;width: 112px;margin-left: 20px;}
.addtocart:hover {background-color: #FF4040;border-color: #FF4040;}
.myaccountwrpsheader .poserrbooksrow {
margin: 5px 0 0px;
justify-content: flex-start;
}
.selectAccountssct {
height: 57px;
padding: 10px 20px;
border: 1px solid #333;
margin-bottom: 20px;
-webkit-appearance: none;
-moz-appearance: none;
appearance: none;
font-size: 22px;
outline: none;
background-image: url("../img/careticons.png");
background-repeat: no-repeat;
background-position: 97% center;
background-size: 19px;
}
.myaccountwrpsheader .poserrbooksrow .posterblogswrp {
display: flex;
align-items: center;
padding: 0px 10px;
}
.myaccountwrpsheader .poserrbooksrow .posterblogswrp .posterblogswrpdetails {
min-height: fit-content !important;
}
.myaccountwrpsheader .poserrbooksrow .posterblogswrp .previewImage {
padding: 0px !important;
width: 120px;
}
.myaccountwrpsheader .poserrbooksrow .posterblogswrp img {
display: table;
float: none;
height: 135px;
margin: 0 auto;
object-fit: contain;
object-position: center;
width: 90%;
}
.myaccountwrpsheader .poserrbooksrow .posterblogswrp .posterblogswrpdetails .postaddcart {
align-items: center;
display: flex;
flex-direction: column;
float: left;
justify-content: center;
width: 100%;
}
.myaccountwrpsheader .poserrbooksrow .posterblogswrp .posterblogswrpdetails .postaddcart h4 {
font-family: Avenir-LT-W01_35-Light1475496;
font-size: 19px;
margin: 0;
position: relative;
text-align: center;
width: auto;
}
.myaccountwrpsheader .poserrbooksrow .posterblogswrp .posterblogswrpdetails .postaddcart h4:after {
background-color: hsla(0,0%,46%,.7);
content: "";
display: table;
float: none;
height: 1px;
margin: 10px auto 13px;
width: 60%;
}
.myaccountwrpsheader .poserrbooksrow .posterblogswrp .posterblogswrpdetails .postaddcart span {
cursor: pointer;
color: #121212;
font-family: Avenir-LT-W01_35-Light1475496;
font-size: 15px;
text-decoration: none;
width: auto;
}
.poserrbooksrow {display: flex;justify-content: center;flex-wrap: wrap;align-items: flex-start;}
.poserrbooksrow {width: 100%;text-align: center;margin: 40px 0 40px;}
.myaccountwrpsheader .poserrbooksrow .posterblogswrp .posterblogswrpdetails {
display: flex;
flex-direction: column;
justify-content: space-between;
min-height: 200px;
padding: 5px 10px 15px;
text-align: center;
}
/* single poster css end here */

/* checkout page css start here */
.checkoutsection {float: left;width: 100%;padding: 35px 0px;min-height: 360px;}
.checkoulistwrps {float: none;width: 65%;margin: 0 80px 0px auto;display: table;position: relative;}
.checkoulistwrps h2 {font-size: 26px;color: #E5A53E;margin: 0 0 10px;}
.checkoutlistwrapper {float: left;width: 100%;}
.checkoutlistwrapper .checkoutlist {
align-items: center;
display: flex;
float: left;
justify-content: flex-start;
margin: 0 0 16px;
position: relative;
width: 100%;
}
.checkoutlistwrapper .checkoutlist .previewImage {
float: left;
width: auto;
}
.myaccountwrpsheader .poserrbooksrow .posterblogswrp {
align-items: center;
display: flex;
padding: 0 10px;
}
.myaccountwrpsheader .poserrbooksrow .posterblogswrp .previewImage {
padding: 0!important;
width: 120px;
}
.myaccountwrpsheader .poserrbooksrow .posterblogswrp .posterblogswrpdetails {
min-height: -webkit-fit-content!important;
min-height: -moz-fit-content!important;
min-height: fit-content!important;
}
.paymentforms.paymentFormsWrapper {
max-height: initial !important;
overflow: initial;
}
.tarnsitionId {
float: left;
width: 100%;
padding: 0px 20px;
font-size: 14px;
position: relative;
font-weight: 100 !important;
margin: 0 0 5px;
}
.tarnsitionId span {
width: auto;
float: none;
color: #949494;
position: relative;
}
.CheckoutModalWrapper .paymentforms .form-groupfiled .form-control, .form-group input {
font-family: "Helvetica-W01-Roman";
font-size: 13px !important;
}
.checkoutlistwrapper .checkoutlist .previewImage img {
float: left;
height: 100px;
margin: 0 15px 0 0;
object-fit: contain;
object-position: center;
position: relative;
width: 100px;
}
.checkoutlistwrapper .checkoutlist img {float: left;width: 140px;margin: 0 15px 0 0;position: relative;}
.checkoutlistwrapper .checkoutlist h5 {float: left;width: auto;font-family: "DIN-Next-W01-Light";font-size: 19px;margin: 0px;color: #000;}
.checkoutpaymnt {float: left;width: 100%;padding: 0 25px;}
.checkoutpaymnt h1 {float: left;width: 100%;font-size: 45px;line-height: normal;margin: 0 0 30px;}
.checkoutpaymnt h2 {font-size: 26px;color: #E5A53E;margin: 0 0 90px;}
.clickpaymnt, .clickpaymnt:focus {border-radius: 0px;background-color: #121212;border-color: #121212;font-size: 17px;padding: 6px 0px;box-shadow: none !important;margin-bottom: 24px;}
.clickpaymnt:hover {background-color: rgba(117,117,117, 1);border-color: rgba(117,117,117, 1);}
.secureimage {width: 105px;margin: 5px auto 0 !important;float: none;display: table;}
.choosebundleModal {float: left;width: 100%;padding: 40px 0px;min-height: 400px;}
.choosebundleModal h1 {float: left;width: 100%;padding: 0px;margin: 0 0 27px;text-align: center;font-size: 45px;}
.chosseCustomNumber {float: left;width: 75%;display: flex;margin: 0 auto;}
.btn.disabled, .btn:disabled, fieldset:disabled .btn {background-color: #121212;border-color: #121212;opacity: 0.3;}
.dropdowncustome {float: right;width: auto;position: relative;}
.dropdowncustome .amountdetailsdropdown {float: left;border: 1px solid rgba(0,0,0,.2);border-radius: 1px;box-shadow: 0 4px 8px 0 rgb(0 0 0 / 10%), 0 0 4px 0 rgb(0 0 0 / 10%);right: -44px;top: 35px;width: 300px;z-index: 1;position: absolute;padding: 14px 15px;font-size: 14px;display: flex;justify-content: space-between;background-color: #fff;align-items: self-start;}
.dropdowncustome .amountdetailsdropdown::after {content: "";box-shadow: -3px -3px 6px rgb(0 0 0 / 10%);height: 10px;right: 43px;top: -5px;transform: rotate(45deg);width: 10px;position: absolute;background-color: #fff;}
.dropdowncustome .dropdowntoggle {display: flex;flex-direction: row;align-items: center;line-height: normal;}
.dropdowncustome .dropdowntoggle:hover {text-decoration: underline;}
.dropdowncustome .amountdetailsdropdown .posterdetails {line-height: normal;}
.dropdowncustome .amountdetailsdropdown .posterdetails span:last-child {font-size: 11px;margin: 1px 0 0;}
/* checkout page css end here */

/* custom radio button css  */
.CustomRadio {display: block;position: relative;padding-left: 46px;margin-bottom: 12px;cursor: pointer;font-size: 30px;-webkit-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;line-height: normal;}
.CustomRadio input {position: absolute;opacity: 0;cursor: pointer;}
.CustomRadio .checkmark {position: absolute;top: 0;left: 0;height: 34px;width: 34px;background-color: #fff;border-radius: 50%;box-shadow: 0px 3px 12px #3d3d3d;}
.checkmark:after {content: "";position: absolute;display: none;}
.CustomRadio input:checked ~ .checkmark:after {display: block;}
.CustomRadio .checkmark:after {top: 9px;left: 0;right: 0;margin: 0 auto;width: 17px;height: 17px;border-radius: 50%;background: #000;}
/* custom radio button css  */

/* checkout modal css start here */
.CheckoutModalWrapper {float: left;width: 700px;height: 100%;background-color: #fff;position: fixed;right: 0;left: 0;margin: 0 auto;top: 0;z-index: 9999;padding: 45px 100px;}
.ModalBackground {float: left;width: 100%;height: 100%;position: fixed;left: 0;top: 0;background: rgba(85,85,85,.75);z-index: 999;}
.closeModal {width: auto;padding: 0px;border: 0px;background: transparent;position: absolute;right: 20px;top: 21px;}
.paymentWrappers {float: left;width: 100%;position: relative;}
.paymentheadermain {float: left;width: 100%;position: relative;margin: 6px 0 13px;}
.paymentheadermain h4 {font-size: 26px;margin: 0px 0px 19px;float: left;width: auto;font-family: "Helvetica-LT-W10-Roman";}
.pymenttitle {
float: left;
width: 100%;
display: flex;
justify-content: space-between;
margin: 0 0 55px;
font-family: "Helvetica-W01-Roman";
}
.pymenttitle div {
width: auto;
}
.paymentssteps {
float: left;
width: 100%;
display: flex;
column-gap: 10px;
margin-bottom: 22px;
}
.paymentssteps div {
height: 5px;
background-color: rgba(0,0,0,.2);
}
.paymentssteps div.active {
background-color: #000;
}
.form-group.error .form-groupfiled input, .form-group.error .form-groupfiled select, .form-group.error .form-groupfiled textarea {
border-color: #F84646;
}
.form-group.error .selectNoInputField {
border-color: #F84646;
}
.form-group.error .form-groupfiled.expirydatswrapper .SiXSY, .form-group.error .form-groupfiled.expirydatswrapper .iqDxdu {
border-color: #F84646 !important;
}
.danger-color {
font-size: 12px;
color: #F84646;
float: left;
width: 100%;
margin: 4px 0 0;
padding-left: 18px;
}
.paymentforms {
float: left;
width: 102% !important;
position: relative;
max-height: calc(100vh - 320px) !important;
overflow: auto;
scrollbar-width: thin; 
scrollbar-color: darkgrey;
padding-right: 10px;
} 
.successModalwraps {
float: left;
position: fixed;
left: 50%;
top: 50%;
transform: translate(-50%, -50%);
z-index: 9999;
text-align: center;
}
.paymentforms::-webkit-scrollbar { width: 5px; } 
.paymentforms::-webkit-scrollbar-track { box-shadow: none; } 
.paymentforms::-webkit-scrollbar-thumb { background-color: darkgrey; border-radius: 10px; } 
.rowcustom-col-2 {display: flex;justify-content: space-between;}
.backpymentbtn {display: flex;align-items: center;cursor: pointer;}
.rowcustom {float: left;width: 100%;margin-bottom: 20px;}
.rowcustom.rowcustom-col-2 .form-group {flex-basis: 49%;}
.form-group {float: left;width: 100%;position: relative;}
.form-group label {float: left;width: 100%;font-size: 14px;margin: 0px 0px 4px;position: relative;font-family: "Helvetica-W01-Roman";}
.form-groupfiled {float: left;width: 100%;position: relative;}
.form-group input {height: 48px;border: 1px solid #E0E0E5;border-radius: 7px;font-size: 16px;box-shadow: none !important;padding: 8px 15px;}
.form-groupfiled .errorsuccessicon {float: left;width: 17px;position: absolute;right: 9px;top: 15px;}
.form-group select.form-control {height: 48px;border: 1px solid #E0E0E5;border-radius: 7px;font-size: 13px;box-shadow: none !important;padding: 8px 17px;background-image: url("../img/selectarrow.svg");background-position: 98% center;background-repeat: no-repeat;background-size: 17px;}
.securepymentnote {float: left;width: 100%;display: flex;flex-direction: row;justify-content: center;align-items: center;font-size: 12px;vertical-align: middle;font-family: "Helvetica-W01-Roman";margin: 8px 0 0;}
.securepymentnote svg {width: 23px;}
.form-group textarea {height: 120px;border: 1px solid #E0E0E5;border-radius: 7px;font-size: 13px;box-shadow: none !important;padding: 8px 17px;resize: none;}
/* checkout modal css end here */

/* country code start here */
.selectNoInputField {float: left;width: 100%;border: 1px solid #E0E0E5;border-radius: 7px;position: relative;display: flex;}
.selectNoInputField input {height: 48px;border: 0px;padding: 10px 10px;font-size: 13px;outline: none;width: 100%;}
.selectNoInputField .inputBoxSelect {width: 100px;text-align: left;margin: 0px;position: relative;padding-left: 16px;}
.selectNoInputField .inputBoxSelect::after{content: "";float: left;width: 1px;height: 25px;background-color: #c1c1c1;position: absolute;right: 0;top: 12px;}
.form-group.error .selectNoInputField {border-color: #F84646;}
.form-group.error .form-groupfiled.expirydatswrapper .SiXSY, .form-group.error .form-groupfiled.expirydatswrapper .iqDxdu {border-color: #F84646 !important;}
.countselet {font-size: 12px;float: left;width: auto;margin: 0 1px 0 0;}
.flagsDropdown {
float: left;
width: 220px;
margin: 0px;
padding: 0px;
position: absolute;
z-index: 9;
background-color: #fff;
box-shadow: 1px 2px 10px rgb(0 0 0 / 35%);
left: 0;
bottom: 51px;
top: auto;
display: none;
}
.flagsDropdown.active {
display: block;
}
.flagsDropdown ul {
float: left;
width: 100%;
padding: 0px;
margin: 0px;
max-height: 200px;
overflow: auto;
}
.flagsDropdown li {
float: left;
width: 100%;
list-style: none;
display: flex;
padding: 10px 13px;
font-size: 12px;
cursor: pointer;
text-align: left;
vertical-align: middle;
align-items: center;
}
.flagsDropdown li:hover {
background-color: #eee;
}
.flagsDropdown li img {
width: 26px;
margin: 0px 8px 0px 0px;
}
.selectNoInputField .inputBoxSelect .selectEmojis {
padding: 1px;
border: 0px;
height: 100%;
width: 100%;
display: flex;
position: relative;
align-items: center;
text-align: center;
justify-content: flex-start;
background-color: transparent;
margin-right: 5px;
}
.selectNoInputField .inputBoxSelect .selectEmojis .flagsimg {
width: 20px;
height: 20px;
border-radius: 100%;
margin: 0px 7px 0px 0px;
object-fit: cover;
}
.selectNoInputField .inputBoxSelect .selectEmojis .arrowdownimg {
width: 7px;
margin: 0px 0px 0px 3px;
}
.btn.formcomnbtn {
background-color: #1D1D1D;
border-color: #1D1D1D;
width: 100%;
padding: 11px 45px;
float: right;
font-size: 16px;
color: #cecece;
border-radius: 0;
}
/* country code end here */

/* success payment page css  */
.successpymentwrapper {
float: none;
width: 475px;
background: #fff;
border-radius: 14px;
position: relative;
text-align: center;
padding: 50px 0 0;
margin: 35px auto 15px;
display: table;
background-position: right top;
background-size: auto;
background-repeat: no-repeat;
}
.successpymentwrapper h4{
float: left;
width: 100%;
font-size: 24px;
margin: 17px 0 10px;
position: relative;
}
.successpymentwrapper p{
float: none;
width: 80%;
margin: 0px auto 16px;
display: table;
font-size: 15px;
line-height: 27px;
color: #949494;
}
.succesiocn {
float: left;
width: 105px;
height: 105px;
background-color: #fff;
border-radius: 100%;
position: absolute;
left: 0;
right: 0;
margin: 0 auto;
top: -53px;
z-index: 9;
overflow: hidden;
}
.totalamount {
float: left;
width: 100%;
padding: 10px 10px 13px;
position: relative;
font-size: 15px;
color: #949494;
}
.totalamount h3 {
margin: 0 0 2px;
font-size: 45px;
font-family: "Avenir-LT-W05_85-Heavy";
color: #000;
}
.successpymentbutns {
float: left;
width: 100%;
display: flex;
padding: 18px 17px;
position: relative;
column-count: 2;
column-gap: 19px;
}
.successpymentbutns .btn {
padding: 16px 16px;
font-size: 14px;
border-radius: 8px;
}
.successpymentbutns .btn.cancelbtn {
background-color: #1D1D1D;
color: #fff;
border-color: #1D1D1D;
}
.successpymentbutns .btn.savebtn, .successpymentbutns .btn.savebtn:focus  {
background-color: #FFFFFF;
color: #000000;
border-color: #000000;
box-shadow: none !important;
}
/* success payment page css  */

/* input type number arrow hide */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
-webkit-appearance: none;
margin: 0;
}
input[type=number] {
-moz-appearance: textfield;
}

/* login sign up page css start here */
.loginsignupModals {
float: left;
width: 100%;
height: 100vh;
z-index: 9999;
display: flex;
align-items: center;
justify-content: center;
background-color: #FFFFFF;
position: fixed;
left: 0;
top: 0;
display: none;
}
.Toastify__close-button--light {
width: auto;
}
#appbody.loginModal .loginsignupModals {
display: flex;
}
.loginsignuptabs {
float: none;
width: 100%;
text-align: center;
}
.loginPopupMain {
float: none;
width: 320px;
margin: 0 auto;
display: table;
text-align: center;
}
.loginsignuptabs h1 {
font-size: 48px;
margin: 0 0 15px;
}
.loginwrapsbtn img {
height: 25px;
width: 25px;
object-fit: cover;
object-position: center;
border-radius: 100%;
}
.loginsignuptabs h4 {
font-size: 17px;
display: flex;
color: #000;
justify-content: center;
align-items: center;
margin: 0 0 37px;
}
.loginsignuptabs h4 span {
cursor: pointer;
color: #0A75B2;
width: auto;
margin: 0 0 0 5px;
}
.fbgoglrbnts {
float: left;
width: 100%;
position: relative;
}
.fbgoglrbnts .btn , .fbgoglrbnts .btn:focus {
display: flex;
justify-content: center;
align-items: center;
border-radius: 0px;
font-family: "Avenir-LT-W05_35-Light";
margin: 0 0 17px;
font-size: 15px;
position: relative;
padding: 8px 14px;
box-shadow: none !important;
}
.fbgoglrbnts .btn .iconsn {
float: left;
width: 20px;
height: 20px;
position: absolute;
left: 14px;
display: flex;
justify-content: center;
align-items: center;
}
.fbgoglrbnts .btn.google .iconsn {
background-color: #fff;
}
.fbgoglrbnts .btn.google .iconsn img {    
width: 13px;
}
.fbgoglrbnts .btn.facebook {
background-color: #3b5998;
}
.fbgoglrbnts .btn.google {
background-color: #4285f4;
}
.ordevidedst {
float: left;
width: 100%;
position: relative;
display: flex;
justify-content: center;
align-items: center;
margin: 0 0 15px;
}
.ordevidedst::after {
content: "";
float: left;
width: 100%;
height: 1px;
background: rgba(199,199,199);
position: absolute;
left: 0;
top: 13px;
}
.ordevidedst span {
width: auto;
background-color: #fff;
float: left;
z-index: 99;
padding: 0px 13px;
}
.loginwithemail, .loginwithemail:focus {
border-radius: 0px;
font-family: "Avenir-LT-W05_35-Light";
margin: 0 0 17px;
font-size: 16px;
position: relative;
padding: 7px 14px;
background: transparent !important;
color: #000 !important;
border-color: rgba(199,199,199);
box-shadow: none !important;
}
.loginwithemail:hover {
border-color: #000 !important;
}
.loginwitemilformwrps {
float: left;
width: 100%;
text-align: left;
}
.loginwitemilformwrps .form-group {
float: left;
width: 100%;
margin: 0 0 30px;
}
.loginwitemilformwrps .form-group label {
color: rgb(117,117,117);
font-size: 16px;
font-family: "Avenir-LT-W01_85-Heavy1475544";
margin: 0px !important;
line-height: normal;
}
.loginwitemilformwrps .form-group .form-control {
border-bottom: 1px solid #C7C7C7 !important;
border-radius: 0px;
height: 34px;
border: 0px;
padding: 5px 0px;
font-size: 15px;
}
.forgotpassword {
float: left;
width: auto;
text-decoration: underline;
font-size: 16px;
margin: 0 0 20px;
cursor: pointer;
}
.loginbtn , .loginbtn:hover, .loginbtn:focus {
border-radius: 0px;
padding: 11px 20px;
background-color: #0A75B2;
border-color: #0A75B2;
font-family: "Avenir-LT-W05_35-Light";
box-shadow: none !important;
}
.ordevidedstmrgin {
margin-top: 30px;
}
.socillinks {
float: left;
width: 100%;
display: flex;
justify-content: center;
align-items: center;
margin: 10px 0 45px;
}
.socillinks div {
width: auto;
margin: 0 15px;
}
.socillinks div .FaceBookIcon {
filter: invert(68%) sepia(33%) saturate(1269%) hue-rotate(182deg) brightness(99%) contrast(84%);
}
/* login sign up page css end here */

/* custom checkbox  css start here */
.customCheckBoxNew .containernew { display: block; position: relative; padding-left: 22px; margin-bottom: 12px; cursor: pointer; font-size: 22px; -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; } 
.customCheckBoxNew .containernew input { position: absolute; opacity: 0; cursor: pointer; height: 0; width: 0; }
.customCheckBoxNew .checkmark { position: absolute; top: 0; left: 0; height: 21px; width: 21px;  background-image: url("../img/heart.png"); background-size: contain; background-position: center; background-repeat: no-repeat; } 
.customCheckBoxNew .containernew input:checked ~ .checkmark { background-color: transparent !important; } 
.customCheckBoxNew .checkmark:after { content: ""; position: absolute; display: none; } 
.customCheckBoxNew .containernew input:checked ~ .checkmark:after { display: block; }
.customCheckBoxNew .containernew .checkmark:after {left: -1px;top: -0.9px;width: 23px;height: 23px; border: none; border-width: 0px; background-image: url("../img/heart-active.png"); background-size: contain; background-position: center; background-repeat: no-repeat; }
/* custom checkbox  css end here */

/* privacy page css start here */
.privacypage {float: left;width: 100%;position: relative;padding: 30px 0px;}
.container.pravcypolicycontainer {max-width: 700px;}
.privacypage h1 {margin: 0px 0px 25px;}
.privacypage p {font-size: 17px;}
.privacypage.RefundPolicypage {padding: 110px 0px;}
/* privacy page css end here */

/* contact us css start here */
.privacypage.RefundPolicypage {
padding: 110px 0px;
}
.contactusform {
float: left;
width: 100%;
position: relative;
}
.privacypage.RefundPolicypage h1 {
text-align: center;
}
.privacypage.RefundPolicypage h4 {
text-align: center;
margin-bottom: 14px;
font-size: 25px;
font-family: "Helvetica-W01-Roman";
}
.contactDetails {
float: left;
width: 100%;
display: flex;
align-items: center;
justify-content: center;
margin: 0 0 55px;
}
.contactDetails div {
width: auto;
margin: 0 15px;
font-size: 17px;
}
.contactDetails div a {
color: #333;
text-decoration: none;
}
.privacypage.RefundPolicypage .paymentformsWraps .form-group input {
font-size: 15px;
}
.contactussubmit .formcomnbtn {
width: auto;
padding: 4px 50px;
font-size: 18px;
}
/* contact us css end here */

.danger-colorset {
font-size: 12px;
color: #F84646;
float: left;
width: 100%;
margin: 4px 0 0;
font-family: "Avenir-LT-W01_85-Heavy1475544";
}
.loginwitemilformwrps .form-group.error .form-control {
border-color: #f84646!important;
}
.loginwitemilformwrps .form-group .input-group-text {
background-color: initial;
border: 0;
cursor: pointer;
position: absolute;
right: 0;
top: 18px;
width: auto;
}
.loginwitemilformwrps .form-group .input-group-text svg {
height: 20px;
width: 20px;
}
.loginwitemilformwrps .form-group .form-control {
border-bottom: 1px solid #c7c7c7!important;
border: 0;
border-radius: 0;
font-size: 15px;
height: 34px;
padding: 5px 0;
}
.headeraccounts, .headerinfolinks {
align-items: flex-end;
display: flex;
float: right;
}
.togglebuttonrespo {
float: left;
height: 22px;
width: 25px;
position: absolute;
right: 29px;
cursor: pointer;
top: 35px;
}
.togglebuttonrespo span {
background-color: #333;
height: 3px;
width: 100%;
border-radius: 4px;
}
.togglebuttonrespo span:first-child {
position: absolute;
top: 0;
}
.togglebuttonrespo span:nth-child(2) {
position: absolute;
left: 0;
top: 9px;
}
.togglebuttonrespo span:last-child {
position: absolute;
bottom: 0;
left: 0;
}
.notdatafound {
float: left;
width: 100%;
text-align: center;
font-size: 20px;
}
.dropdousericon {margin: 0px 18px -3px 10px;height: 26px !important;width: 26px !important;}
.loginwrapsbtn:hover h5 {
color: rgba(127, 204, 247);
}
.loginwrapsbtn:hover .dropdousericon {
fill: rgba(127, 204, 247);
}
.commonpopupbuttons {float: left;width: 100%;text-align: center;}
.CommonDropdownWraps {float: left;width: 130px;background-color: #fff;position: absolute;top: 55px;bottom: auto;z-index: 9;padding: 10px 10px;right: 0;border: 1px solid #D7D7D7;}
.CommonDropdownWraps ul {padding: 0px;margin: 0px;float: left;width: 100%;}
.CommonDropdownWraps ul li {float: left;width: 100%;list-style: none;position: relative;}
.CommonDropdownWraps ul li a {cursor: pointer;text-decoration: none;color: #000;padding: 10px 10px;font-size: 15px;font-family: "Avenir-LT-W01_85-Heavy1475544";}
.CommonDropdownWraps ul li a:hover {color: rgb(117, 117, 117);}
.CommonDropdownWraps ul li:last-child::before {content: "";float: left;width: 100%;height: 1px;background-color: #D7D7D7;position: absolute;top: 0;left: 0;}
.bookspreviewModal {
float: left;
width: 862px;
position: fixed;
left: 0;
top: 0;
height: 100%;
right: 0;
margin: 0 auto;
background-color: #fff;
z-index: 9999;
padding: 50px 40px;
}
.container.custom_container {max-width: 800px;display: table;}
.container.custom_container_new {max-width: 980px;display: table;}
.successpymentwrapper p b {
width: auto;
float: none;
color: #333;
}
/* account section details */
.accoutntwrapper {float: left;width: 100%;position: relative;padding: 40px 0;}
.myacountsatabswrps {float: left;width: 100%;position: relative;}
.myaccountprofilepic {float: left;width: 100%;border: 1px solid #333;text-align: center;padding: 25px 20px;position: relative;margin: 0 0 25px;}
.myaccountprofilepic h4 {float: left;width: 100%;font-family: "Avenir-LT-W01_85-Heavy1475544";font-size: 16px;}
.accountsRouting {float: left;width: 100%;border: 1px solid #333;padding: 14px 32px;position: relative;}
.accountsRouting a {float: left;width: 100%;font-family: "Avenir-LT-W01_85-Heavy1475544";font-size: 17px;text-decoration: none;color: #333;margin: 0 0 15px;line-height: normal;}
.accountsRouting a:hover, .accountsRouting a.active {color: #0A75B2;}
.accountsRouting a:last-child {margin-bottom: 0px;}
.acctprofile {float: none;width: 90px;height: 90px;margin: 0 auto 11px;cursor: pointer;position: relative;overflow: hidden;border-radius: 100%;display: block;}
.acctprofile img {float: left;width: 100%;height: 100%;object-fit: cover;object-position: center;}
.acctprofile .uploadfileovarlay {float: left;width: 100%;height: 100%;background: rgba(0,0,0,0.4);position: absolute;left: 0;top: 0;cursor: pointer;justify-content: center;flex-direction: column;align-items: center;display: none;}
.acctprofile:hover .uploadfileovarlay {display: flex;}
.acctprofile .uploadfileovarlay svg {width: 29px;height: 29px;border-radius: 100%;padding: 2px;}
.acctprofile .uploadfileovarlay input[type="file"] {opacity: 0;position: absolute;}
.acctprofile .uploadfileovarlay .svgwrp {width: 29px;height: 29px;background: rgba(255, 255, 255, 0.9);border-radius: 100%;display: flex;justify-content: center;align-items: center;position: relative;cursor: pointer;z-index: 99;}
.noPosterTitle {font-size: 16px;color: #adadad;margin: 25px 0 30px;}
/* account section details */

.myaccountwrps {float: left;width: 100%;border: 1px solid #333;padding: 25px 25px;position: relative;margin: 0 0 25px;}
.myaccountwrpsheader {float: left;width: 100%;border-bottom: 1px solid rgba(18, 18, 18, 0.2);padding-bottom: 5px;margin-bottom: 25px;}
.myaccountwrpsheader .headertitle {float: left;width: auto;display: inline-grid;}
.myaccountwrpsheader .headertitle h4 {font-family: "Avenir-LT-W01_85-Heavy1475544";font-size: 38px;margin: 0 0 10px;}
.myaccountwrpsheader .headertitle p {font-family: "Avenir-LT-W01_85-Heavy1475544";float: left;width: 100%;margin-bottom: 20px;}
.myaccountwrpsheader .bttngroup {float: right;width: auto;display: inline-flex;}
.discardbtn {color: rgb(10, 117, 178) !important;background-color: transparent !important;border-color: rgb(10, 117, 178);border-width: 1px;width: auto;font-size: 15px;padding: 7px 15px;border-radius: 0px;font-family: "Avenir-LT-W01_85-Heavy1475544";margin-right: 11px;box-shadow: none !important;}
.discardbtn:hover {color: rgba(10, 117, 178, 0.7);border-color: rgba(10, 117, 178, 0.7);}
.updteinfobtn {color: #fff;background-color: rgb(10, 117, 178);border-color: rgb(10, 117, 178);border-width: 1px;width: auto;font-size: 15px;padding: 7px 15px;border-radius: 0px;font-family: "Avenir-LT-W01_85-Heavy1475544";box-shadow: none !important;}
.updteinfobtn:hover  {color: #fff;border-color: rgba(10, 117, 178, 0.7);background-color: rgba(10, 117, 178, 0.7);}
.accountwrps {float: left;width: 100%;}
.accountwrps h4 {float: left;width: 100%;font-family: "Avenir-LT-W01_85-Heavy1475544";font-size: 19px;margin: 0 0 10px;}
.accountwrps h5 {float: left;width: 100%;font-family: "Avenir-LT-W01_85-Heavy1475544";font-size: 17px;margin: 0 0 27px;}
.paymentformsWraps {float: left;width: 100%;}
.commonpopup {float: left;width: 582px;position: fixed;top: 50%;left: 50%;transform: translate(-50%, -50%);z-index: 9999;padding: 80px 32px;background-color: #fff;}
.commonpopup .closepopup {background: transparent;border: 0px;box-shadow: none;position: absolute;right: 13px;top: 20px;width: 32px;cursor: pointer;}
.commonpopup .commonpopupdetails {float: left;width: 100%;text-align: center;}
.commonpopup .commonpopupdetails h1 {font: normal normal normal 32px/1.25em avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif;}
.commonpopup .commonpopupdetails p {font: normal normal normal 16px/2em avenir-lt-w01_85-heavy1475544,avenir-lt-w05_85-heavy,sans-serif;width: 87%;float: none;margin: 0 auto 30px;display: table;}
.cancelmodal, .cancelmodal:focus {width: auto;border: 1px solid #000000;border-radius: 0px;background: transparent;color: #000000;padding: 7px 40px;font-family: "Avenir-LT-W01_85-Heavy1475544";float: none;margin: 0 5px;box-shadow: none !important;outline: none !important;}
.cancelmodal:hover {color: rgba(0, 0, 0, 0.7);background: transparent;border-color: rgba(0, 0, 0, 0.7);}
.discardmodal, .discardmodal:focus {width: auto;border: 1px solid #000000;border-radius: 0px;background: #000000;color: #fff;padding: 7px 40px;font-family: "Avenir-LT-W01_85-Heavy1475544";float: none;margin: 0 5px;box-shadow: none !important;outline: none !important;}
.discardmodal:hover {background-color: rgba(0, 0, 0, 0.7);border-color: rgba(0, 0, 0, 0.7);color: rgba(255, 255, 255, 0.7);}
.commonpopupbuttons {float: left;width: 100%;text-align: center;}
.imageloader {
float: left;
width: 100%;
height: 100%;
position: absolute;
background-color: rgba(255,255,255,0.7);
top: 0;
left: 0;
display: flex;
align-items: center;
justify-content: center;
}
.paymentformsWrapsaccount .form-group label{
float: left;
width: 100%;
font-family: "Avenir-LT-W01_85-Heavy1475544";
font-size: 16px;
margin: 0 0 10px;
}
.paymentformsWrapsaccount .form-group .selectNoInputField input {
font-size: 15px;
}
.noteemail {
    color: rgba(18, 18, 18, 0.5);
}
.rowcustomright {
float: right;
width: auto;
text-align: right;
}
.accountsRoutingLink {
cursor: pointer;
}
.loginwrapsbtn img {
height: 25px;
width: 25px;
object-fit: cover;
object-position: center;
border-radius: 100%;
}
.imageloader img {
width: 70px;
height: 70px;
}
.danger-colorset {
font-size: 12px;
color: #F84646;
float: left;
width: 100%;
margin: 4px 0 0;
font-family: "Avenir-LT-W01_85-Heavy1475544";
}
.bookspreviewModal ul {
float: left;
width: 100%;
padding: 0px;
margin: 0px;
}
.modalBckgrounds {
float: left;
width: 100%;
height: 100%;
position: fixed;
left: 0;
top: 0;
}
.bookspreviewModal ul li {
float: left;
width: 100%;
list-style: none;
font-size: 17px;
font-family: "Avenir-LT-W01_85-Heavy1475544";
}
.bookspreviewModal .closePreviewModal {
border: 0px;
background: transparent;
width: 30px;
height: 30px;
padding: 0px;
position: absolute;
right: -35px;
top: 10px;
cursor: pointer;
}

.paginationCustom {
float: left;
width: 100%;
text-align: center;
}
.paginationCustom #rpb-pagination {
}
.paginationCustom #rpb-pagination li {
width: auto;
font-family: "Avenir-LT-W01_85-Heavy1475544";
}
.paginationCustom #rpb-pagination li button {
border: 0px;
box-shadow: none;
}
.paginationCustom #rpb-pagination li:first-child, .paginationCustom #rpb-pagination li:last-child {display: none;} 
.paginationCustom #rpb-pagination li button {
    box-shadow: none;
    border-radius: 100%;
    width: 32px !important;
    min-width: 32px !important;
    height: 32px;
    color: rgb(145, 145, 145) !important;
}
.paginationCustom #rpb-pagination li .rpb-item.rpb-item--active {
    border: 1px solid rgb(145, 145, 145);
    background-color: transparent;
}
iframe {
    left: 0;
}

/* responsive sidebar css start here */
.responsiveSidebar {
float: left;
width: 400px;
position: fixed;
right: 0;
top: 0;
background: rgba(255, 255, 255, 255);
z-index: 9999;
height: 100%;
padding: 30px 30px;
transform: translateX(100%);
-webkit-transition: 0.5s;
-moz-transition: 0.5s;
-o-transition: 0.5s;
transition: 0.5s;
}
.responsiveSidebar .headeraccounts {
width: 100%;
padding: 0px 0 34px;
}
.responsiveSidebar .headerLinks ul {
display: block;
}
.responsiveSidebar .headerLinks ul li {
width: 100%;
float: left;
margin: 0 0 29px;
}
.loginwrapsbtn h5 {
font-size: 19px;
}
.responsiveSidebar .headerLinks ul li a {
font-size: 20px;
padding: 5px 30px;
font-family: "Avenir-LT-W01_85-Heavy1475544";
}
.togglebuttonrespo {
float: left;
height: 22px;
width: 25px;
position: absolute;
right: 29px;
cursor: pointer;
top: 35px;
}
.togglebuttonrespo span {
background-color: #333;
height: 3px;
width: 100%;
border-radius: 4px;
}
.togglebuttonrespo span:first-child {
position: absolute;
top: 0;
}
.togglebuttonrespo span:nth-child(2) {
position: absolute;
left: 0;
top: 9px;
}
.togglebuttonrespo span:last-child {
position: absolute;
bottom: 0;
left: 0;
}
.selectAccountssct {
height: 57px;
padding: 10px 20px;
border: 1px solid #333;
margin-bottom: 20px;
-webkit-appearance: none;
-moz-appearance: none;
appearance: none;
font-size: 22px;
outline: none;
background-image: url("../img/careticons.png");
background-repeat: no-repeat;
background-position: 97% center;
background-size: 19px;

}
.responsiveSidebar.active {
transform: translateX(0%);
-webkit-transition: 0.5s;
-moz-transition: 0.5s;
-o-transition: 0.5s;
transition: 0.5s;
}
#responsiveSidebarMain .headeraccounts .togglebuttonrespo {
top: 3px;
right: 9px;
}
#responsiveSidebarMain .headeraccounts .togglebuttonrespo span:nth-child(2) {
display: none;
}
#responsiveSidebarMain .headeraccounts .togglebuttonrespo span:first-child {
transform: rotate(45deg);
top: 12px;
}
#responsiveSidebarMain .headeraccounts .togglebuttonrespo span:last-child {
transform: rotate(-45deg);
top: 12px;
}
/* responsive sidebar css end here */

/* Loader start here */
.loaderwrapper {
    float: left;
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 99999999;
    background: rgba(255,255,255, 0.9);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.loaderwrapper .loader {
    border: 5px solid #070000;
    border-radius: 50%;
    border-top: 5px solid transparent;
    width: 100px;
    height: 100px;
    animation: spin 0.9s linear infinite;
}
/* Safari */
@-webkit-keyframes spin {
0% { -webkit-transform: rotate(0deg); }
100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
0% { transform: rotate(0deg); }
100% { transform: rotate(360deg); }
}
/* Loader end here */

.sitenotavailbale {
float: left;
width: 100%;
text-align: center;
font-size: 25px;
height: calc(100vh - 260px);
display: flex;
justify-content: center;
align-items: center;
}
.pageisLoading {
float: left;
width: 100%;
height: 100%;
position: fixed;
left: 0;
top: 0;
display: flex;
justify-content: center;
align-items: center;
background-color: #fff;
z-index: 999;
flex-direction: column;
}
.pageisLoading img {
width: 80px;
}
input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
    display: none;
}
.passwordnotewrapper {
float: right;
width: auto;
position: relative;
}
.passwordnotewrapper ol {
float: left;
width: 200px;
background-color: #fff;
position: absolute;
right: 0;
z-index: 999;
bottom: 18px;
padding: 10px 10px 10px 25px;
margin: 0px;
box-shadow: 0px 0px 27px -6px #ccc;
border-radius: 9px;
display: none;
}
.passwordnotewrapper ol li {
font-size: 11px;
}
.passwordnotewrapper:hover ol {
    display: block;
}
.paswordfield label img {
    cursor: pointer;
    height: 13px;
    object-fit: contain;
    object-position: center;
    width: 13px;
}
.passwordFieldwrap {
    float: left;
    width: 100%;
    position: relative;
}
.loginsignuptabsresetpssword .loginPopupMain .form-group label {
    margin: 0 0 8px!important;
}
.loginsignuptabsresetpssword .passwordFieldwrap input {
    height: 40px!important;
    padding: 5px 10px!important;
}
.passwordFieldwrap input {
    border: 1px solid #C7C7C7 !important;
}
.passwordFieldwrap .input-group-text {
    top: 0 !important;
    height: 100% !important;
}

.loginwitemilformwrps .form-group label .passwordnotewrapper {
float: none;
width: auto;
position: relative;
display: inline-block;
margin: 0 0px 0px 6px;
}
.loginwitemilformwrps .form-group label .passwordnotewrapper img{
width: 13px;
}
.loginwitemilformwrps .form-group label .passwordnotewrapper ol {
left: 0;
right: auto;
}

.formcomnbtn .spinner-border {
height: 23px;
width: 23px;
margin: 2px 6px 0 0px;
border-width: 4px;
}

.formcomnbtn .spinner-border {
height: 23px;
width: 23px;
margin: 2px 6px 0 0px;
border-width: 4px;
float: none;
}
    
.payloader .formcomnbtn .spinner-border {
margin: -5px 6px 0px 0px;
float: none;
position: relative;
top: 3px;
}
.loaderwrappernew {
display: flex;
flex-direction: column;
}

.loaderwrappernew h4 {
margin: 29px 0 0;
font-size: 21px;
}

/* cvv show hide password */
.cvv-groupfiled .input-group-text {
width: auto;
background: transparent;
border: 0px;
padding: 0px;
position: absolute;
right: 10px;
top: 15px;
cursor: pointer;
}
.cvv-groupfiled .input-group-text svg {
width: 18px;
height: 18px;
}
.cvv-groupfiled .errorsuccessicon {
right: 37px;
}